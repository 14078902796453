import React, {useEffect, useState} from 'react';
import s from './VersatilePay.module.scss';
import classNames from "classnames";
import axios from "axios";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "../CheckoutForm/CheckoutForm";

interface IVersatilePay {
    id: string
}

const stripePromise = loadStripe("pk_live_51I5OI6HS6oDn6V5FZz3oBL6w7SCYzBTI4Cq1gT55YPUsAIDfWRcoOQOEgJXORlwZX3AZV3pdpXz3WTw8zSuNtGgH00n1n9PnUA");

const VersatilePay = ({id}: IVersatilePay) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any>();
    const [options, setOptions] = useState<any>();

    const fetchData = async () => {
        const response = await axios.get("https://api.versatile.best/get-payment", {
            params: {
                payment_id: id
            }
        })
        setData(response.data)

        setOptions({
            clientSecret: response.data.client_secret,
            appearance,
        })

        setIsLoading(false)
    }

    const appearance = {
        theme: 'stripe',
        variables: {
            borderRadius: '0',
        },
    };

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <section className={s.pay}>
            <div className="container">
                {/*<h1>Versatile Pay</h1>*/}
                {!isLoading && <div className={s.pay__block}>
                    <div className={s.payments}>
                        <div className={s.payments__info}>
                            <div className={s['payments__info-total']}>
                                <span>Total Payable Payments</span>
                                <h1>${(data.amount / 100).toFixed(2)}</h1>
                            </div>
                            <div className={s.payments__items}>
                                {/*<div className={s['payments__items-item']}>*/}
                                {/*    <div className={s['payments__items-item__title']}>*/}
                                {/*        <span*/}
                                {/*            className={s['payments__items-item__title-info']}>1 Year Subscription</span>*/}
                                {/*        <span>Professional Plan</span>*/}
                                {/*    </div>*/}
                                {/*    <span>$150.00</span>*/}
                                {/*</div>*/}
                                {data.items.map((i: any, index: number) => <div className={s['payments__items-item']}
                                                                                key={index}>
                                    <div className={s['payments__items-item__title']}>
                                        <span>{i.item_name}</span>
                                    </div>
                                    <span>${(i.item_price / 100).toFixed(2)}</span>
                                </div>)}
                                <div className={classNames(s['payments__items-item'], s['payments__items-item_total'])}>
                                    <div className={s['payments__items-item__title']}>
                                        <span>Total Charge</span>
                                    </div>
                                    <span>${(data.amount / 100).toFixed(2)}</span>
                                </div>
                            </div>
                        </div>
                        <ul className={s.payments__links}>
                            <li>Powered by Versatile</li>
                        </ul>
                    </div>
                    <div className={s.form}>
                        {/*<ApplePay/>*/}
                        {/*<div className={s.form__or}>*/}
                        {/*    <span>Or pay with Card</span>*/}
                        {/*</div>*/}
                        {options.clientSecret.length && (
                            //@ts-ignore
                            <Elements options={options} stripe={stripePromise}>
                                <CheckoutForm clientSecret={data.client_secret} successUrl={data.success_url}/>
                            </Elements>
                        )}
                        {/*<form action="" className={s.form__form}>*/}
                        {/*    <Input placeholder={'Email address'} label={'Enter your Email Address'} isSmall={true}/>*/}
                        {/*    <Input placeholder={'Card Number'} label={'Enter Card Number'} isSmall={true}*/}
                        {/*           id={"card-number"} readOnly={true}/>*/}
                        {/*    <div className={s['form__form-row']}>*/}
                        {/*        <div className={s['form__form-row']}>*/}
                        {/*            <Input placeholder={'Month'} label={'Expiry Date'} isSmall={true}*/}
                        {/*                   id={"expiry-month"} readOnly={true}/>*/}
                        {/*            <Input placeholder={'Year'} label={' '} isSmall={true} id={"expiry-year"}*/}
                        {/*                   readOnly={true}/>*/}
                        {/*        </div>*/}
                        {/*        <Input placeholder={'Enter CVV'} label={'CVV Number'} isSmall={true}*/}
                        {/*               id={"security-code"} readOnly={true}/>*/}
                        {/*    </div>*/}
                        {/*    <Input placeholder={'Enter Card Holder Name'} label={'Name'} isSmall={true}*/}
                        {/*           id={"cardholder-name"} readOnly={true}/>*/}
                        {/*    <Input placeholder={'Australia'} label={'Country of Region'} isSmall={true}/>*/}
                        {/*    <Button text={'Pay $175.00'} className={s['form__form-button']} onClick={(e: any) => {*/}
                        {/*        e.preventDefault();*/}
                        {/*    }}/>*/}
                        {/*</form>*/}
                    </div>
                </div>}
            </div>
        </section>
    );
};

export default VersatilePay;