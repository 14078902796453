import React, {useEffect, useState} from 'react';
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import s from "./CheckoutForm.module.scss"
import Button from "../UI/Button/Button";

interface CheckoutFormProps {
    clientSecret: string
    successUrl: string
}

const CheckoutForm = ({clientSecret, successUrl}: CheckoutFormProps) => {
    const stripe = useStripe();
    const elements = useElements();

    const [state, setState] = useState<"requires_payment_method" | "processing" | "succeeded" | null>(null)
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
            switch (paymentIntent!.status) {
                case "succeeded":
                    setState("succeeded");
                    window.location.href = successUrl
                    break;
                case "processing":
                    setState("processing");
                    break;
                case "requires_payment_method":
                    setState("requires_payment_method");
                    break;
                default:
                    setError("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const {error} = await stripe.confirmPayment({
            elements,
            redirect: "if_required"
        });

        if (error) {
            if (error.type === "card_error" || error.type === "validation_error") {
                setError(error.message!);
            } else {
                setError("An unexpected error occurred.");
            }
        } else {
            window.location.href = successUrl
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <>
            <form id="payment-form" onSubmit={handleSubmit} className={s.form}>
                {state === "requires_payment_method" && <>
                    {/* @ts-ignore */}
                    <PaymentElement id="payment-element" options={{...paymentElementOptions, fields: {billingDetails: {name: "auto", email: "auto"}}}}/>
                    <Button disabled={isLoading || !stripe || !elements} text={isLoading ? "Loading..." : "Pay now"}
                            id="submit"/>
                </>}
                {state === "processing" && <span className={s.form__message}>Your payment is being processed.</span>}
                {state === "succeeded" && <span className={s.form__message}>Your payment is successful.</span>}
                {error && <div className={s.form__error}>{error}</div>}
            </form>
        </>
    );
};

export default CheckoutForm;