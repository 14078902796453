import React from 'react';
import Hero from "../../components/Hero/Hero";
import VersatilePay from "../../components/VersatilePay/VersatilePay";
import {useParams} from "react-router-dom";

const Pay = () => {
    let {id} = useParams();

    return (
        <>
            <main className={"wrapper"}>
                <Hero isBackground={true} isFullHeight={true}/>
                <VersatilePay id={id!}/>
            </main>
        </>
    );
};

export default Pay;